@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Jost:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Jost:ital,wght@0,100..900;1,100..900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap");

html body {
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 14px !important;
  background-color: #e6e7e8 !important;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Red Hat Display", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  color: #051920;
  margin-bottom: 0 !important;
}

.MuiDataGrid-columnHeaderTitle {
  color: #3f4254 !important;
  font-weight: 900 !important;
  font-size: 15px !important;
  font-family: "Red Hat Display", sans-serif;
  text-transform: uppercase !important;
}

.MuiDataGrid-columnHeaderTitle:hover {
  color: var(--bs-gray-700) !important;
}

.MuiDataGrid-cellContent,
.MuiDataGrid-cell--withRenderer,
.table-loader-label {
  color: #051920 !important;
  font-family: "DM Sans", sans-serif;
  font-size: 0.9rem !important;
  letter-spacing: 1.1px;
  font-weight: 500 !important;
}

input[type="time"] {
  display: inline-block;
  position: relative;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.rmsc .dropdown-container {
  position: relative !important;
  outline: 0 !important;
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
}

input[type="date"] {
  display: inline-block;
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.form-label {
  color: #051920 !important;
}

.app-sidebar {
  font-family: "Red Hat Display", sans-serif;
  font-size: 15px;
}

.css-sfb686-MuiSvgIcon-root {
  color: #07427c !important;
}

.css-sfb686-MuiSvgIcon-root:hover {
  color: #009abc !important;
}

.MuiTablePagination-root .MuiTablePagination-actions .MuiButtonBase-root {
  background: #b6bbc4 !important;
}

#kt_app_header {
  background-color: #e6e7e8 !important;
  border-bottom: 1px solid #b6bbc4;
}

.flag-dropdown {
  background-color: #f9f9f9 !important;
  border: none !important;
}

.flag-dropdown:hover {
  background-color: #f9f9f9 !important;
}

/* .btn.btn-primary { background-color: #009abc !important; } */
.btn.btn-primary:hover:not(.btn-active) {
  background-color: #0286a3 !important;
}

.btn.btn-primary,
.app-main .btn.btn-primary:hover:not(.btn-active) {
  background-color: #009abc;
}

.btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-primary:focus,
.btn.btn-primary:hover:not(.btn-active) {
  background: #0286a3 !important;
  color: #fff;
}

.login-form-container {
  min-height: 100vh;
  overflow: auto;
}

.bg-image-shape-box {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #009abc;
}

.login-bg-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  z-index: 1;
}

.login-bg-shape {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
}

.login-form-box {
  background-color: #e6e7e8;
  border-radius: 30px;
  color: #051920;
  position: relative;
  padding: 4rem;
  width: 540px;
  margin-left: auto;
}

.logo-box {
  max-width: 300px;
  margin: 0 auto;
  width: 100%;
}

.logo-box img {
  width: 100%;
  height: auto;
}

.login-form-box h1 {
  color: #07427c;
  font-size: 40px;
  font-weight: 900;
}

.form-check.form-check-inline {
  cursor: pointer;
}

.login-form-box .text-primary {
  color: #009abc !important;
}

.fs-20 {
  font-size: 20px;
  font-weight: 700;
}

.login-form-box .btn.btn-primary,
.login-form-box .btn.btn-primary:hover:not(.btn-active) {
  background-color: #009abc;
  color: #fff;
}

.login-form-box .btn.btn-primary:hover,
.login-form-box .btn.btn-primary:hover:not(.btn-active),
.login-form-box .btn.btn-primary:focus:not(.btn-active) {
  background-color: #0286a3 !important;
  color: #ffffff;
}

.login-form-box .link-primary {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}

.login-form-box .link-primary,
.login-form-box .link-primary:hover,
.login-form-box .link-primary:focus {
  color: #009abc !important;
}

.login-form-box .form-control {
  background-color: #b6bbc4;
  padding-right: 3rem;
}

.login-form-box .form-check-input {
  border-color: #b6bbc4;
  cursor: pointer;
}

.login-form-box .form-check-input:checked {
  background-color: #009abc;
  border-color: #009abc;
}

[data-kt-app-layout="light-sidebar"]
  .app-sidebar
  .menu
  .menu-item.hover:not(.here)
  > .menu-link:not(.disabled):not(.active):not(.here)
  .menu-arrow:after,
[data-kt-app-layout="light-sidebar"]
  .app-sidebar
  .menu
  .menu-item:not(.here)
  .menu-link:hover:not(.disabled):not(.active):not(.here)
  .menu-arrow:after {
  background-color: #009abc !important;
}

[data-kt-app-layout="light-sidebar"]
  .app-sidebar
  .menu
  .menu-item:not(.here)
  .menu-link:hover:not(.disabled):not(.active):not(.here)
  .menu-icon
  svg {
  fill: #009abc;
}

div .form-control {  
  box-shadow: none;
  outline: none;
  border-color: #e6e7e8 !important;
  color: #051920 !important;
}

.app-sidebar-logo {
  text-align: center;
  border-color: transparent !important;
}

.app-sidebar-logo a {
  display: table;
  margin: 0 auto;
}

.app-page,
.app-page .app-footer {
  background: #e6e7e8;
}

.page-heading {
  font-weight: 900;
  color: #07427c;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: left;
  margin-left: 70px;  
}

.app-wrapper .app-sidebar {
  background: #07427c !important;
}

[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item
  .menu-link
  .menu-icon
  i,
[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item
  .menu-link
  .menu-title {
  color: #e6e7e8;
  transition: 0.35s all;
}

[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item
  .menu-link.active {
  background-color: #009abc;
  color: #f2f2f2;
}

[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item
  .menu-link.active
  .menu-icon
  i,
[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item
  .menu-link.active
  .menu-title {
  color: #fff;
}

[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item:not(.here)
  .menu-link:hover:not(.disabled):not(.active):not(.here)
  .menu-title,
[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item:not(.here)
  .menu-link:hover:not(.disabled):not(.active):not(.here)
  .menu-icon
  i {
  color: #009abc;
}

[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item.show
  > .menu-link
  .menu-icon
  i,
[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item.show
  > .menu-link
  .menu-title {
  color: #009abc !important;
}

.app-sidebar-toggle {
  height: 24px;
  width: 54px;
  padding-left: 30px;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.app-sidebar-toggle svg {
  width: 100%;
  height: auto;
}

.app-sidebar-toggle-ico svg,
.app-sidebar-toggle svg {
  fill: #07427c;
}

.app-sidebar-logo .app-sidebar-toggle {
  background: transparent !important;
  box-shadow: none !important;
}

[data-kt-app-sidebar-fixed="true"] .app-wrapper {
  transition: 0.25s all;
}

.fmenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.fmenu ul li a {
  color: #051920;
  text-decoration: none;
}

.fmenu ul li a:hover {
  color: #009abc;
}

.fmenu ul li {
  margin: 5px 30px 5px 0;
  line-height: 1;
}

.fmenu ul li:last-child {
  margin-right: 0;
}

.top-search {
  max-width: 300px;
  width: 100%;
  position: relative;
}

.top-search i {
  position: absolute;
  left: 1rem;
  bottom: 0.9rem;
  color: #9a9a9a;
}

.top-search i .path1:before {
  opacity: 1;
}

.top-search .form-control.form-control-solid {
  padding-left: 3.3rem;
  background: #e9e9e9;
  border-color: #e6e7e8;
  color: #051920;
}

.form-control.form-control-solid:focus {
  border-color: #b6bbc4 !important;
  
}

.card .MuiDataGrid-root {
  border-color: #e6e7e8;
}

.card .MuiDataGrid-main .MuiDataGrid-row .MuiDataGrid-cell {
  font-size: 13px !important;
  border-color: transparent;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiDataGrid-columnHeaders
  .MuiDataGrid-columnHeadersInner
  .MuiDataGrid-columnHeader:focus {
  outline: none;
}

.MuiDataGrid-columnHeaders
  .MuiDataGrid-columnHeadersInner
  .MuiDataGrid-columnHeader
  .MuiDataGrid-columnHeaderTitle {
  color: #051920 !important;
  font-weight: 700 !important;
  text-transform: none !important;
  font-size: 1.1rem !important;
  font-family: "DM Sans", sans-serif;
  white-space: pre-wrap;
  line-height: normal;
}

.MuiDataGrid-columnHeaders
  .MuiDataGrid-columnHeadersInner
  .MuiDataGrid-columnHeader {
  border-color: transparent;
  padding: 0 1.5rem;
}

.card .MuiDataGrid-columnHeaders,
.card .MuiDataGrid-root .MuiDataGrid-footerContainer {
  border-color: transparent !important;
}

.card .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row {
  background: #e6e7e8;
}

.card .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row:nth-child(2n + 2) {
  background: #fff;
}

.MuiTablePagination-root .MuiTablePagination-selectLabel,
.MuiTablePagination-root .MuiInputBase-root,
.MuiTablePagination-root .MuiTablePagination-displayedRows {
  color: #051920;
  font-size: 14px;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
}

.MuiTablePagination-root .MuiTablePagination-actions .MuiButtonBase-root {
  background: #ecebdf;
  color: #051920;
  margin-right: 10px;
}

.MuiTablePagination-root .MuiTablePagination-actions .MuiButtonBase-root:hover {
  background: #009abc !important;
  color: #fff;
}

.MuiTablePagination-root
  .MuiTablePagination-actions
  .MuiButtonBase-root:last-child {
  margin-right: 0;
  align-items: center;
}

.MuiDataGrid-footerContainer .MuiDataGrid-selectedRowCount {
  color: #051920;
  font-size: 14px;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
}

/* .app-content .form-select.form-select-solid,
.app-content .form-select.form-select-solid,
.app-content .form-control.form-control-solid {
  background-color: #e9e9e9;
  border-color: #b6bbc4;
  color: #051920 !important;
} */

.notification-ico {
  position: relative;
  margin: 0 1rem 0 0.5rem;
  cursor: pointer;
}

.notification-ico figure {
  height: 20px;
  width: 20px;
  margin: 0;
}

.notification-ico svg {
  width: 100%;
  height: auto;
  fill: #051920;
}

.notification-ico .count {
  position: absolute;
  top: -7px;
  right: -2px;
  height: 15px;
  width: 15px;
  background: #eb7627;
  color: #fff;
  font-weight: 600;
  font-size: 0.8rem;
  border-radius: 10px;
  text-align: center;
  line-height: 15px;
}

.app-container .card {
  position: relative;
  background-color: #f2f2f2;
}

.top-title {
  position: absolute;
  left: 1.5rem;
  right: 14rem;
  z-index: 5;
  top: 2.3rem;
}

.top-title h5 {
  margin: 0;
}

.filter-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.filter-btn {
  height: 40px;
  width: 40px;
  min-width: 40px;
}

.filter-list ul li {
  margin: 0.5rem;
  background: #8081844d;
  padding: 0.7rem 2.6rem 0.6rem 1.3rem;
  border-radius: 100px;
  position: relative;
}

.filter-list ul li:first-child {
  margin-left: 0;
}

.filter-close-ico {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0.7rem;
  height: 1.4rem;
  width: 1.4rem;
  background: #ecebdf;
  border: 1px solid #fff;
  border-radius: 50px;
  font-weight: bold;
  padding: 0;
  line-height: 1.2rem;
  text-align: center;
  cursor: pointer;
  display: block;
  transition: 0.35s all;
  color: #051920;
}

.filter-close-ico:hover {
  background: #009abc;
  border-color: #009abc;
  color: #fff;
}

.dashboard-card-box.card {
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 30px 0px #0519200d;
  border-radius: 14px;
  z-index: 1;
  background: #fff;
  height: 100%;
  min-height: 200px;
  width: 95%;
}

.dashboard-card-box.card:hover {
  background: #009abc;
}

.dashboard-card-box .card-body {
  transition: 0.35s all;
  display: flex;
  flex-direction: column;
}

.dashboard-card-box h5 {
  color: #051920;
  font-size: 1.5rem;
  font-weight: 500;
  transition: 0.35s all;
}

.dashboard-card-box h2 {
  transition: 0.35s all;
}

.dashboard-card-box:hover h5,
.dashboard-card-box:hover h2 {
  color: #fff;
}

.dashboard-card-box figure {
  width: 100px;
  min-width: 100px;
  margin: 0 0 0 auto;
}

.dashboard-card-box figure svg {
  fill: #009abc;
  width: 100%;
  transition: 0.35s all;
}

.dashboard-card-box h3 {
  color: #051920;
  font-size: 5rem;
  font-weight: 700;
  margin: 0;
  font-family: "DM Sans", sans-serif;
  transition: 0.35s all;
}

.dashboard-card-box:hover h3 {
  color: #fff;
}

.dashboard-card-box:hover figure svg {
  fill: #fff;
}

.rate-time {
  color: #051920;
  transition: 0.35s all;
  white-space: nowrap;
}

.dashboard-card-box:hover .rate-time {
  color: #ffffff;
}

.btn.rate-time {
  background: rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  align-items: center;
  transition: 0.35s all;
}

.btn.rate-time:after {
  content: "";
  height: 0;
  width: 0;
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  margin: 0 -0.4rem 0 0.6rem;
  transition: 0.35s all;
}

.dashboard-card-box:hover .btn.rate-time {
  background: rgba(255, 255, 255, 0.2);
}

.dashboard-card-box:hover .btn.rate-time:after {
  border-top-color: rgba(255, 255, 255, 0.7);
}

.dashboard-card-bottom {
  margin-top: auto;
}

.card-bubble {
  position: absolute;
  height: 16rem;
  width: 16rem;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 50%;
  z-index: -1;
}

.dashboard-card-01 .card-bubble {
  bottom: -17%;
  right: -6%;
}

.dashboard-card-02 .card-bubble {
  top: -42%;
  right: -13%;
}

.dashboard-card-03 .card-bubble {
  top: 13%;
  left: -21%;
}

.dashboard-card-04 .card-bubble {
  bottom: -24%;
  right: 13%;
}

.dashboard-card-05 .card-bubble {
  top: -40%;
  left: 50%;
  transform: translateX(-50%);
}

.dashboard-card-06 .card-bubble {
  top: 10%;
  right: -17%;
}

/*-------------- Steps -------------*/
.steps-list{
  margin: 0;
  overflow: hidden;  
  display: flex;
  justify-content: center;  
  font-family: "Red Hat Display", sans-serif;
  color: #7e8299;
  font-size: 16px;  
  font-weight: 500;
}
.steps-list li {
  list-style-type: none;  
  width: 100%;  
  position: relative;  
  text-align: center;
  display: flex;
  justify-content: center;
}
.steps-list li:before, .steps-list li:after{
  content: '';
  width: 50%;
  height: 2px;
  background: #e6e7e8;
  position: absolute;  
  top: 25px;   
}
.steps-list li:before {
 left: 0;
}
.steps-list li:after {
  right: 0;
 }
 .steps-list li .nav-link {
  border: none;
  background: transparent;
 }
.steps-list li .nav-link .step-icon {
  width: 50px;
  height: 50px;  
  display: flex;  
  justify-content: center;  
  background: #e6e7e8;
  border-radius: 50%;
  margin: 0 auto 10px auto;  
  z-index: 2;
  position: relative;
  align-items: center;
}

.steps-list li.active .nav-link {
  color: #009ABC;  
}
.steps-list li.active .step-icon, .steps-list li.active:before, .steps-list li.active:after {
  background: #009ABC;
}
.steps-list li:last-child::after, .steps-list li:first-child::before {
  display: none;
}


@media only screen and (max-width: 1399px) {
  .dashboard-card-box figure {
    width: 70px;
    min-width: 70px;
  }

  .dashboard-card-box h3 {
    font-size: 4rem;
  }

  .dashboard-card-box h5 {
    font-size: 1.4rem;
  }

  .dashboard-card-bottom {
    padding-top: 5rem;
  }

  .card-bubble {
    height: 12rem;
    width: 12rem;
  }
}

@media only screen and (max-width: 1199px) {
  .login-form-box-box {
    padding: 4.5rem;
  }

  .dashboard-card-box h3 {
    font-size: 3.2rem;
  }

  .dashboard-card-box figure {
    width: 60px;
    min-width: 60px;
  }

  .dashboard-card-bottom {
    padding-top: 3rem;
  }
}
@media only screen and (max-width: 1199px) {
  .page-heading {margin-left: 20px;}
}



@media only screen and (max-width: 767px) {
  .login-form-box {
    padding: 4rem;
  }

  .top-title {
    position: static;
    margin-bottom: 1.5rem;
  }

  .dashboard-card-box figure {
    width: 70px;
    min-width: 70px;
  }

  .login-form-box {
    padding: 3rem 2rem;
    width: 100%;
  }

  .login-form-box h1 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .login-form-box-box {
    padding: 3rem;
  }

  .top-search {
    max-width: 100%;
  }
}

.table-search {
  max-width: 300px;
  width: 100%;
}

.table-search .form-control {
  background-color: #e0dee7;
  border-color: #e0dee7;
  color: #303030;
  transition: 0.35s all;
  padding-left: 35px;
}

.table-search .form-control:focus {
  border-color: #e0dee7;
  background-color: #fff;
}

.with-search-ico {
  position: relative;
  z-index: 1;
}

.with-search-ico:after {
  content: "";
  height: 12px;
  width: 12px;
  border: 2px solid #666666;
  position: absolute;
  left: 14px;
  top: 14px;
  border-radius: 50px;
  z-index: 3;
}

.with-search-ico:before {
  content: "";
  height: 6px;
  width: 2px;
  transform: rotate(-45deg);
  background-color: #666666;
  position: absolute;
  left: 24px;
  top: 22px;
  border-radius: 50px;
  z-index: 3;
}

.table-add-btn {
  position: absolute;
  right: 0;
  top: 10px;
}

.card
  .MuiDataGrid-main
  .MuiDataGrid-row
  .MuiDataGrid-cell.MuiDataGrid-cell--textLeft {
  justify-content: start;
}

[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item.show
  > .menu-link
  .menu-icon
  i,
[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item.show
  > .menu-link
  .menu-title {
  color: #009abc !important;
}

[data-kt-app-layout="light-sidebar"]
  .app-sidebar
  .menu
  .menu-item.here
  > .menu-link
  .menu-arrow:after {
  background-color: #009abc !important;
}

[data-kt-app-layout="light-sidebar"]
  .app-sidebar
  .menu
  .menu-item.here
  > .menu-link
  .menu-title {
  color: #fff;
}

.header-user .symbol {
  border: 1px solid #666666;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.MuiTablePagination-root p {
  margin: 0;
}

.MuiTablePagination-root .MuiInputBase-root {
  margin: 0 1rem;
}

.MuiBox-root .MuiSvgIcon-colorPrimary {
  color: #009abc;
  transition: 0.35s all;
}

.MuiBox-root .MuiSvgIcon-colorPrimary:hover {
  color: #009abc;
}

.MuiBox-root .MuiSvgIcon-colorError {
  color: #fa4f4f;
  transition: 0.35s all;
}

.MuiBox-root .MuiSvgIcon-colorError:hover {
  color: #d9214e;
}

@media only screen and (max-width: 767px) {
  .table-add-btn {
    position: static;
  }
}

#kt_modal_create_app .ql-editor.ql-blank , .ql-editor {
 min-height: 10rem !important;
}
.red-asterisk {
  color: #FA4F4F !important;
}

.close-icon {
  position: absolute;
  right: auto;
  top: -10px;
  border-radius: 50%;
  background: #eeeeee;
  height: 24px;
  width: 24px;
  cursor: pointer;
}